<template>
  <div class="content-box">
    <div class="content-viewer">
      <div class="document-bar-box">
        <el-button class="document-back" type="text" icon="el-icon-arrow-left" @click="onBack">返回首页</el-button>
        <span class="document-name">{{ documentName }}</span>
        <div v-if="documentName" class="document-btn-box">
          <el-button v-if="!unlocked" type="primary" size="small" class="iconfont icon-yuedu" @click="onPay">付费阅读</el-button>
          <el-button v-if="!unlocked" type="primary" size="small" class="iconfont icon-kefu" @click="onRegist">联系商家阅读</el-button>
          <el-button v-else type="primary" size="small" class="iconfont icon-print" @click="onPrint">打印</el-button>
        </div>
      </div>
      <document-viewer ref="viewer" class="document-viewer" :loading="loading" :percentage="percentage"></document-viewer>
    </div>
    <footer-bar-for-qingstudy></footer-bar-for-qingstudy>
  </div>
</template>

<script>
import DocumentViewer from "@/components/PC/DocumentViewer";
import HeaderEntry from "@/components/PC/HeaderEntry";
import FooterBarForQingstudy from "@/components/PC/FooterBarForQingstudy";
import Tools from "@/tool";
import Config from "@/api/viewer";
import Manager from "@/manager";
export default {
  name: "PCModule",
  components: {
    DocumentViewer,
    HeaderEntry,
    FooterBarForQingstudy,
  },
  data() {
    return {
      loading: true,
      percentage: 0,
      unlocked: null,
      documentGuid: null,
      documentName: null,
    };
  },
  methods: {
    //获取路由参数
    getRoutParams() {
      this.documentGuid = this.$route.query.documentGuid;
      if (this.$route.query.token) {
        this.$store.commit("setToken", this.$route.query.token);
        // this.$router.replace({
        //   name: "Viewer",
        //   query: {
        //     documentGuid: this.$route.query.documentGuid
        //   }
        // });
      }
      if (!this.$store.getters.isLogin) {
        this.$loginDialog.show(false);
      }
    },
    //获取是否购买文档
    getDocumentPurchase() {
      if (!this.$store.getters.isLogin) {
        return;
      }
      var model = {
        DocumentGuid: this.documentGuid,
      };
      this.$axios
        .post(Config.document.purchased, model)
        .then((res) => {
          this.unlocked = res.data.Data.IsPurchased;
        })
        .catch((error) => {
          this.$dialog({ title: "错误", message: error.message });
        });
    },
    //查询资料信息
    getDocumentInfo() {
      var model = {
        DocumentGuid: this.documentGuid,
      };
      return this.$axios
        .post(Config.document.info, model)
        .then((res) => {
          var data = res.data.Data;
          this.documentName = data.DocumentName;
          window.document.title = data.DocumentName;
        })
        .catch((error) => {
          this.$message.error(error.message);
        });
    },
    //获取文档密码
    getDocumentPassword() {
      if (!this.$store.getters.isLogin) {
        return;
      }
      var model = {
        DocumentGuid: this.documentGuid,
      };
      return this.$axios
        .post(Config.document.password, model)
        .then((res) => {
          this.documentPassword = res.data.Data.Password;
        })
        .catch((error) => {
          this.$message.error(error.message);
        });
    },
    //下载文档
    getDocumentUrl() {
      if (!this.$store.getters.isLogin) {
        return;
      }
      this.loading = true;
      //下载文档
      var model = {
        DocumentGuid: this.documentGuid,
        ProxyCode: this.$store.state.proxyCode,
      };
      return this.$axios
        ({
          method: "get",
          url: Config.document.download,
          params: model,
          responseType: "arraybuffer",
          onDownloadProgress: (evt) => {
            this.percentage = parseInt((evt.loaded / evt.total) * 100);
          },
        })
        .then((res) => {
          this.documentUrl = window.URL.createObjectURL(new Blob([res.data]));
        })
        .catch((error) => {
          this.$message.error(error.message);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    //加载文档
    loadDocument() {
      if (!this.$store.getters.isLogin) {
        return;
      }
      if (this.$refs.viewer) {
        this.$refs.viewer.open(this.documentGuid, this.documentUrl, this.documentPassword);
      }
    },
    //点击首页
    onBack() {
      window.location.href = Manager.goHomeUrl(this.$store.state.proxyCode, this.documentGuid);
    },
    //点击在线支付
    onPay() {
      if (!this.$store.getters.isLogin) {
        this.$loginDialog.show(true);
      } else {
        window.location.href = Manager.goDocumentPreviewUrl(this.$store.state.proxyCode, this.documentGuid);
        // window.location.href = Manager.goDocumentPaymentUrl(this.$store.state.proxyCode, this.documentGuid);
      }
    },
    //点击联系客服激活
    onRegist() {
      if (!this.$store.getters.isLogin) {
        this.$loginDialog.show(true);
      } else {
        window.location.href = Manager.goDocumentPreviewUrl(this.$store.state.proxyCode, this.documentGuid);
        // window.location.href = Manager.goDocumentRegistUrl(this.$store.state.proxyCode, this.documentGuid);
      }
    },
    //点击打印
    onPrint() {
      if (!this.$store.getters.isLogin) {
        this.$loginDialog.show(true);
      } else {
        window.location.href = Manager.goDocumentPrintUrl(this.$store.state.proxyCode, this.documentGuid);
      }
    }
  },
  async mounted() {
    //通过判断域名、是否已经登录、是否传递token，来判断该链接是否是分享访问。
    if (window.location.hostname == 'handebook.qingstudy.cn' && !this.$store.getters.isLogin && !this.$route.query.token) {
      this.$alert('为了方便同步登录信息，请从网站跳转观看，该域名暂不支持分享链接方式访问。', '提示');
      return;
    }

    try {
      await this.getRoutParams();
      await this.getDocumentPurchase();
      await this.getDocumentInfo();
      await this.getDocumentPassword();
      await this.getDocumentUrl();
      this.loadDocument();
    } catch (error) {
      this.$message.error(error.message);
    }
  }
};
</script>
<style scoped>
.content-box {
  display: flex;
  flex-direction: column;
  margin: 0 20px;
}

.content-viewer {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #ededf0;
  border: 1px solid lightgray;
  margin-bottom: 5px;
}

.document-bar-box {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 20px;
}

.document-btn-box {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
}

.document-logo {
  width: 15px;
  height: 15px;
}

.document-name {
  color: #1e1e1e;
  margin-left: 20px;
  margin-left: auto;
}

.document-viewer {
  border-top: 1px solid lightgray;
}

.iconfont::before {
  margin-right: 2px;
}

.content-list {
  margin-top: 50px;
}

.content-border {
  border: 1px solid #e6e6e6;
  padding: 10px 30px;
}
</style>